ul.ui.menu,ol.ui.menu {
  list-style: none;
  padding: 0;
}
.ui.tiles {
  .tile {
    display: block;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1em 0 1em 0;
    text-align: center;
    @include viewport(mobile, only) {
      font-size: 0.5em; 
    } 
    i {
      font-size: 2rem; 
      display: block;
      margin: auto;
      margin-bottom: 5px;
      @include viewport(medium, up) {
        font-size: 3rem; 
      }      
    }
  }
}
.ui.breadcrumbs {
  ul.breadcrumbs {
    list-style: none;
    padding: 0;   
    margin: 0;
  }
  .crumb {
    display: inline-block;
    &:after {
      content: "/";
      padding: 0 0.5em;
    } 
    &:last-child:after {
      content: ""; 
      padding: 0;
    }
    &:only-child {
      display: none; 
    }
  }
}

.form-control {
  width: 100%;
}

form .field.checkbox {
  display: block !important;
}

.action {
  cursor: pointer;
}

.ui.list-group {
  padding: 0;
  margin: 1em 0;
  border-radius: 5px;
  li {
    list-style: none; 
  }
  .item {
    padding: 0.35em;
    border: 1px solid #eee; 

    @include clearfix;

    &:nth-child(even) {
      background: #efefef; 
    }

    &.success {
      background-color: $backgroundColorSuccess; 
    }

    &.error {
      background-color: $backgroundColorError; 
    }

    .item-action,.item-actions {
      float: right; 
      display: inline-block;
    }
  }
}

.ui.primary.button {
  background-color: $primaryColor;
  &:hover {
    background-color: lighten($primaryColor,5%); 
  }
}

.ui.modal  {
  i.close {
    top: 0.5rem !important;
    right: 0.5rem !important;
    color: $darkGray !important;
  }
  > .content > {
    :last-child.actions, :last-child fieldset.actions:last-child {
      margin: 1rem -1rem -1rem -1rem;  
      padding: 0.5rem;
      max-width: none;
      
    }
  }
}

table.ui.table {
  tfoot {
    background: #f0f0f0; 
    border-top: 1px solid #d4d4d5;
  }
  @include viewport(mobile){
    thead,tfoot{
      display: none !important; 
    } 
    tr td.actions {
      text-align: right; 
    }
  }
}
.ui.statistic {
  max-width: none;
}

.map-canvas {
  height: 500px;

}
.sortable-item {
  transition: all .125s;
}

.sortable-item.is-dragging {
  background: red;
  transition-duration: 0s;
  z-index: 10;
}

.sortable-item.is-dropping {
  background: #f66;
  z-index: 10;
}

.sortable-item .handle {
  display: inline-block;
  padding: 0.5em 1em 0.5em 0.5em;
  cursor: move;
}
.fa.error{
  color:red;
}
.scrolling.undetached.dimmable.dimmed > .dimmer {
    overflow: scroll !important;
}

.scrolling.undetached.dimmable.dimmed{
    overflow: hidden !important;
}