$breakpoints: (
"mobile" : (0px, 768px),
"small"  : (769px, 1024px),
"medium" : (1024px, 1200px),
"large"  : (1200px, 1600px),
"xlarge" : (1600px, 3900px)
);

@mixin viewport($size, $base: only) {
  $range: map-get($breakpoints, $size);
  @if $range != null {
    @if $base == up {
      @media only screen and (min-width: nth($range, 1)) {
        @content;
      }
    }
    @else if $base == only {
      @media only screen and (min-width: nth($range, 1)) and (max-width: nth($range, 2)) {
        @content;
      }
    }
    @else if $base == down {
      @media only screen and (max-width: nth($range, 1)) {
        @content;
      }
    }
  }
  @else {
    @warn "No suitable media query value for '#{$size}'.";
  }
}
