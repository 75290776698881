@import 'settings';
@import 'mixins';
@import 'bower_components/font-awesome/scss/font-awesome';
//@import 'font-awesome';
@import 'breakpoints';
@import 'ui-elements';


* {
  box-sizing: border-box;
}

a, a:visited {
  color: $primaryColor;
  &:hover {
    color: $alternateColor;
  }
}

pre {
  white-space: normal;
  margin-top: 0;
}

html, body {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

body {
  overflow-x: hidden;
}

dl {
  margin-bottom: 1em;
  dt {
    font-weight:bold;
    margin: 0.5em 0 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
  dd {
    margin: 0 0 0 0.25em;
    font-weight: normal;
  }
}

form fieldset.actions {
  border: none;
  padding: 0.5em 0 0 0;
  margin: 1em 0;
  border-top: 1px solid #999;
  text-align: right;
}

input:not(.expanded), select:not(.expanded), textarea:not(.expanded), fieldset:not(.expanded) {
  max-width: 35em;
}
fieldset {
  padding: 0.5em 0;
  border-right: 0;
  border-left: 0;
  margin-bottom: 1em;
}
.field {
  max-width: 35em;
}

h1,h2,h3 {
  padding-top: 0.5em;

  @include viewport(mobile, only) {
    font-size: 1.2em;
  }
}

hgroup {
  > {
    h1,h2,h3,h4,h5,h6 {
      margin-bottom: 0;
      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
  margin-bottom: 1.5rem;
}

menu {
  padding: 0;
  margin: 0;
  menuitem {
    cursor: pointer;
  }
}


body > .ember-view {
  overflow-x:hidden;
  position: relative;
  min-height: 100vh;
  padding-top: 50px;
  > main, > footer {
    padding: 1em;
  }
  > header {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    height: 50px;
    background: $darkGray;
    color: #fff;
    padding-left: 50px;
    .menu-toggle {
      line-height: 50px;
      font-size: 35px;;
      color: #fff;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
    }
    h1.logo {
      float: left;
      margin: 0 1em 0 0;
      padding: 0;
      a {
        overflow: hidden;
        display:block;
        text-indent: 155px;
        width: 78px;
        height: 50px;
        background: url('/logo.jpg')
      }
    }
    
    nav.user-menu {
      float: right;
      > .menu {
        margin: 0;
        > .item {
          color: #fff;
        }
        .dropdown.item > .menu{
          left: inherit;
          right: 0;
          width: auto;
          .item {
            width: auto;

          }
          a {
            color: #000;
          }
        }
      }
    }
  }
  nav#navMain {
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 100;
    width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0;
    transition: width 0.25s ease-in-out;
  }
  > #notifications {
      position: fixed;
      right: 0;
      top: 10vh;
      z-index: 1000;
      width: 300px;
      max-width: 49vw;
      .alert {
        position: relative;
        min-height: 1em;
        margin: 1em 0;
        background: #f8f8f9;
        padding: 1em 1.5em;
        line-height: 1.4285em;
        color: rgba(0,0,0,.87);
        transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease;
        border-radius: .28571429rem;
        box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset,0 0 0 0 transparent;
        border: 2px solid #000;
      }
      .alert-warning {
        background-color: #fffaf3;
        color: #573a08;
        box-shadow: 0 0 0 1px #c9ba9b inset,0 0 0 0 transparent;
      }
      .alert-info {
        color: #276f86;
      }
      .alert-success {
        background-color: #fcfff5;
        box-shadow: 0 0 0 1px #a3c293 inset,0 0 0 0 transparent;
        color: #2c662d;
      }
      .alert-error {
        background-color: #fff6f6;
        color: #9f3a38;
        box-shadow: 0 0 0 1px #e0b4b4 inset,0 0 0 0 transparent;
      }
  }
  > main {

    width: 100vw;
    padding-bottom: 120px;
    overflow-x: hidden;

    > nav.breadcrumbs {
      background: #ccc;
      margin: -1em -1em 0 -1em;
      > .breadcrumbs {
        padding: 0.5em;
        font-size:0.8em;
      }
    }

    >article > header {
      > hgroup > h1, > h1 {
        margin: 0 -1rem 0 -1rem;
        font-size: 1.1em;
        padding: 0.5em;
        background: #ddd;

      }
    }

  }
  > footer {
    background: $darkGray;
    color: white;
    height: 100px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.menu-on  {
    padding-left: 15rem;
    transition: padding-left 0.25s ease-in-out;
    nav#navMain {
      width: 15rem;
    }
    > footer, > header {
      left: 15rem;
      transition: left 0.25s ease-in-out;
    }
  }
  @include viewport(medium, up) {
    padding-left: 15rem;
    > header {
      padding-left: 1em;
      .menu-toggle {
         display: none;
      }
    }
    main {
      width: auto;

    }
    nav#navMain {
      width: 15rem;
      top: 50px;
      bottom: 100px;
    }
    > footer, > header {
      left: 0;
    }
    &.menu-on  {
      padding-left: 15rem;
      > footer, > header {
        left: 0;
      }
    }
  }
}

header {
  margin-bottom: 1em;
  position: relative;
  .primary-action {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5em;
    font-size: 1.2em;

  }
}
.toggle {
  cursor: pointer;
}
/* Pages */
fieldset.trips ol {
  counter-reset: trips-counter;
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    margin: 0.25em 0 1em 0;
    &:before {
      content: "Trip " counter(trips-counter);
      counter-increment: trips-counter;
      display: block;
      border-bottom: 2px solid #999;
      padding: 0.1em 0 0.1em 0;
      margin-bottom: 0.5em;
      font-size: 1.5em;
    }
  }
}

.align-right {
  text-align: right !important;
}
.align-center {
  text-align: center !important;
}
.align-left {
  text-align: left !important;
}

.hidden-small {
  @include viewport(small,down) {
    display: none;
  }
}

@media print {
  body > .ember-view > {
    header, nav, footer {
      display: none;
    }
  }
}
